<template>
  <v-dialog max-width="500" scrollable v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <v-icon left>mdi-tune</v-icon>
        Filtrage
        <v-spacer/>

        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4" v-if="loading"></v-card-text>
      <v-card-text class="pa-4" v-else>
        <v-row>

          <v-col cols="12">
            <v-autocomplete
                outlined
                prepend-icon="mdi-filter-outline"
                multiple
                chips
                deletable-chips
                small-chips
                dense
                clearable
                :items="suppliers"
                v-model="filter.selectedsuppliers"
                :item-text="'name'"
                :item-value="'id'"
                label="Suppliers..."
                hide-details
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                      :src="getImageLink(slotProps.item.image)"
                      max-height="25"
                      max-width="25"
                      width="25"
                      height="25"
                      class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-autocomplete>

          </v-col>

          <v-col cols="12">
            <v-autocomplete
                outlined
                prepend-icon="mdi-filter-outline"
                multiple
                chips
                deletable-chips
                small-chips
                dense
                clearable
                :items="services"
                v-model="filter.selectedservices"
                :item-text="'name'"
                :item-value="'id'"
                label="Services..."
                hide-details
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                      :src="getImageLink(slotProps.item.image)"
                      max-height="25"
                      max-width="25"
                      width="25"
                      height="25"
                      class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-autocomplete>

          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            :disabled="isDisabled"
            depressed
            block
            @click="handleFilter"
        >
          Recherche
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      services: [],

      loading: false,
      filter: {
        selectedservices: [],
        selectedsuppliers: [],
      },
    };
  },

  methods: {
    open(filter) {
      this.dialog = true;
      this.filter.selectedsuppliers = filter.suppliers;
      this.filter.selectedservices = filter.services;
    },
    getServices() {
      this.loading = true;

      HTTP.get("/service/voucher", {params: {}})
          .then((res) => {
            this.services = res.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
    },
    fetchSuppliers() {
      this.$store.dispatch("fetchSuppliers");
    },
    handleFilter() {
      this.$emit("filter", this.filter);
    },
  },

  computed: {
    isDisabled() {
      return false;

      //   return Object.values(this.filter).every(
      //     (x) => x === null || x === "" || x.length === 0
      //   );
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
  },

  created() {
    this.getServices();
    this.fetchSuppliers();
  },
};
</script>